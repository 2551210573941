import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from './PreviewCompatibleImage'

const FeatureGrid = ({ gridItems }) => (
  <div className="columns is-multiline is-mobile">
    {gridItems.map((item, index) => (
      <div key={index} className="column is-one-third-desktop is-half-tablet is-full-mobile">
        <Link className="box" to={item.link}>
          <PreviewCompatibleImage imageInfo={item} />
          <p class="title is-4 mt-3">{item.title}</p>

          <div class="content">
            {item.text}
            <br/><br/>
            <p>
              <button className="button is-primary">
                Plus d'infos
              </button>
            </p>
          </div>
        </Link>
      </div>
    ))}
  </div>
)

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.html,
    })
  ),
}

export default FeatureGrid
